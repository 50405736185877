<template>
    <!--h1 class="h1 white1 ">{{ $t("title-kompoziti") }}</h1-->
    <v-card elevation="0" class="pt-4 pb-0 px-8" min-width="100%">
          <v-card-text class="divider line one-line-tamnosiva divider_slova_naslov_tamnosivi text-center">
            {{ $t("title-cimmer") }}
          </v-card-text>
      </v-card>
    
    
    <!--p class="p white1">{{ $t("description-kompoziti") }}</p-->

      <!--v-divider
        :thickness="3"
        class="border-opacity-100"
        color="black"
        inset
        width="90%"
      ></v-divider-->

   

    <v-card elevation="0" class="pt-0 pb-4 py-4" min-width="100%">
      <v-card-text class="tekst_white1">
        {{ $t("description-cimmer") }}
      </v-card-text>
    </v-card>
    <!--h2 class="h2 white1">{{ $t("subtitle1-kompoziti") }}</h2-->
  


    <v-row>

      <v-card elevation="0" class="pa-8" min-width="100%">
          <v-card-text class="frontpodnaslov_white1 text-center">
            {{ $t("subtitle1-kompoziti") }}
          </v-card-text>
      </v-card>

    </v-row>


    <!-- v-row>
      <v-sheet
        class="mx-auto sheet_format" 
        max-width="100%"
        >
        <v-slide-group
                  v-model="cimmerproduktindex"
                  class="pa-4"
                  center-active
                  show-arrows
                  >
                  <v-slide-group-item 
                      v-for="n in Object.keys(cimmerprodukti) "
                      :key="cimmerprodukti[n].Slug"
                      v-slot="{ isSelected, toggle }"
                      >

                          <v-card 
                              :color="isSelected ? '#0c0c0c00' : 'white'"
                              @click="toggle"
                              class="ma-2" max-width="300" min-width="300" >

                              
                              <v-img class="white--text align-end" cover height="250px" :src="cimmerprodukti[n].SlikaString" lazy-src="https://upload.wikimedia.org/wikipedia/commons/f/fc/No_picture_available.png">
                              
                                  <v-card-title class="transparentna_traka" > {{  $t(cimmerprodukti[n].Naziv) }}</v-card-title>
                              </v-img>

                          </v-card>

                      </v-slide-group-item>
                  
        </v-slide-group>


        <v-row justify="center" >
            <v-expand-transition min-width="90%">
                <v-sheet
                
                    v-if="cimmerproduktindex != null"
                    :elevation="0"
                    
                    max-width="90%"
                    min-width="90%"


                    
                >
                    <v-container   class=" container" >

                      <v-row >
                        <v-col cols="12" sm="12" >
                          <v-card elevation="0"  min-width="100%">
                                    <v-card-text class="naslov_kartica text-center">
                                      {{ $t(cimmerprodukti[cimmerproduktindex].Naziv) }}
                                    </v-card-text>
                                </v-card>
                        </v-col>
                      </v-row>
                        <v-row class="justify-between">

                            <v-col  cols="12" sm="6" >
 

                                <div class="pozadinakolona_lijevo_kartica">                   
                                  <div class="tekst_kartica" > {{skratiOpis($t(cimmerprodukti[cimmerproduktindex].Opis), 550)}}</div>   
                                </div>                                     
                                                                

                            </v-col>
                            <v-col cols="12" sm="6"  >
                                <div class="pozadinakolona_desno_kartica">

                                    <v-img cover  :src="cimmerprodukti[cimmerproduktindex].SlikaString" lazy-src="https://upload.wikimedia.org/wikipedia/commons/f/fc/No_picture_available.png"></v-img>
                                  </div>
                             
                            </v-col>

                        </v-row>
                        <v-row justify="center">
                          <v-col  cols="12" sm="5">

                          </v-col>
                        </v-row>                  
                    </v-container>
                </v-sheet>
            </v-expand-transition>
        </v-row>
          


      </v-sheet>
    </v-row -->
 
    <v-row class="fill-height ma-4">
        <v-col
        v-for="(src, index) in imgs"
        :key="index"
        
        @click="() => showImg(index)"
        class="d-flex child-flex"
        cols="2"
        >
        <v-img cover  class="bg-grey-lighten-2" :src="src" >
            <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                    <v-progress-circular
                    indeterminate
                    color="grey-lighten-5"
                    ></v-progress-circular>
                </v-row>
            </template>
        </v-img>
        </v-col>
    </v-row>
  <v-row class="ma-4">

      <vue-easy-lightbox
        :visible="visible"
        :imgs="imgs"
        :index="index"
        @hide="handleHide"
      ></vue-easy-lightbox>
    </v-row>


    <v-row><v-spacer></v-spacer></v-row>
    
    <v-row><v-spacer></v-spacer></v-row>



    
</template>

<script>

import VueEasyLightbox from 'vue-easy-lightbox' /**https://github.com/XiongAmao/vue-easy-lightbox */

export default {
    
    components: {
      VueEasyLightbox
    },

    data () {
      return {

        visible: false,
        index: 0, // default: 0
        imgs: [
            '/images/cimmer/produkt-cimmer-1.jpg',
            '/images/cimmer/produkt-cimmer-2.jpg',
            '/images/cimmer/produkt-cimmer-3.jpg',
            '/images/cimmer/produkt-cimmer-4.jpg',
            '/images/cimmer/produkt-cimmer-5.jpg',
            '/images/cimmer/produkt-cimmer-6.jpg',
            '/images/cimmer/produkt-cimmer-7.jpg',
            '/images/cimmer/produkt-cimmer-8.jpg',
            '/images/cimmer/produkt-cimmer-9.jpg',
            '/images/cimmer/produkt-cimmer-10.jpg',
            '/images/cimmer/produkt-cimmer-11.jpg',
            '/images/cimmer/produkt-cimmer-12.jpg',                        
            '/images/cimmer/produkt-cimmer-13.jpg',
            '/images/cimmer/produkt-cimmer-14.jpg',
            '/images/cimmer/produkt-cimmer-15.jpg',
            '/images/cimmer/produkt-cimmer-16.jpg',            
            { src: '/images/cimmer/produkt-cimmer-17.jpg', title: '17' },
            { src: '/images/cimmer/produkt-cimmer-18.jpg', title: '18' }
        ],


        cimmerproduktindex:null,
        cimmerprodukti:[],

        
        //snackbar
        alert:false,
        alerttext:'',
        alertsnackbar:false,
        snackbartekst:'',
        snackbartitle:'',
        multiLine: true,
        ikonasnackbar:'',
        colorsnackbar:'',          
        }

      },
      created() {
        this.loadcimmerprodukti();

    
      },

      methods:{

        /* od galerije zoom */
          showImg(index) {
          this.index = index
          this.visible = true
        },
        handleHide() {
          this.visible = false
        },

        skratiOpis(opis, maksimalniBrojKaraktera) {
          
          if (opis.length > maksimalniBrojKaraktera) {
            return opis.substring(0, maksimalniBrojKaraktera) ;
          } else {
            return opis;
          }
        },        


        loadcimmerprodukti(){
          this.cimmerprodukti=[
            {Naziv:'produkt-cimmer-1', Opis:'produkt-cimmer-opis-1', Slug:"1", SlikaString:"/images/proizvodi/produkt-kompoziti-1.jpg" },
            {Naziv:'produkt-cimmer-1', Opis:'produkt-cimmer-opis-1', Slug:"1", SlikaString:"/images/proizvodi/produkt-kompoziti-2.jpg" },
            {Naziv:'produkt-cimmer-1', Opis:'produkt-cimmer-opis-1', Slug:"1", SlikaString:"/images/proizvodi/produkt-kompoziti-3.jpg" }


          ]
        },        
        async obavijestHeading(strTekst, tip){

            switch(tip) {
            case 'info':
                this.ikonasnackbar="info";
                this.colorsnackbar="info";
                this.snackbartitle="Info"
                break;
            case 'success':
                this.ikonasnackbar="check_circle";
                this.colorsnackbar="success";
                this.snackbartitle="Obavijest"
                break;
            case 'error':
                this.ikonasnackbar="error";
                this.colorsnackbar="error";
                this.snackbartitle="Greška"
                break; 
            case 'warning':
                this.ikonasnackbar="warning";
                this.colorsnackbar="warning";
                this.snackbartitle="Upozorenje"
                break;                                        
            default:

            }

            this.snackbartekst=strTekst;
            this.alertsnackbar=true;


        },        
      }
    
}
</script>
<style >

</style>