<template>
    <!--h1 class="h1 white1 ">{{ $t("title-metali") }}</h1-->
    <v-card elevation="0" class="pt-4 pb-0 px-8" min-width="100%">
          <v-card-text class="divider line one-line-tamnosiva divider_slova_naslov_tamnosivi text-center">
            {{ $t("title-metali") }}
          </v-card-text>
      </v-card>
    
    
    <!--p class="p white1">{{ $t("description-metali") }}</p-->

      <!--v-divider
        :thickness="3"
        class="border-opacity-100"
        color="black"
        inset
        width="90%"
      ></v-divider-->

   

    <v-card elevation="0" class="pt-0 pb-4 py-4" min-width="100%">
      <v-card-text class="tekst_white1">
        {{ $t("description-metali") }}
      </v-card-text>
    </v-card>
    <!--h2 class="h2 white1">{{ $t("subtitle1-metali") }}</h2-->
  


    <v-row>

      <v-card elevation="0" class="pa-8" min-width="100%">
          <v-card-text class="frontpodnaslov_white1 text-center">
            {{ $t("subtitle1-metali") }}
          </v-card-text>
      </v-card>

    </v-row>


    <v-row>
      <v-sheet
        class="mx-auto" 
        max-width="100%"
        >
        <v-slide-group
                  v-model="metaliproduktindex"
                  class="pa-3"
                  center-active
                  show-arrows
                  >
                  <v-slide-group-item 
                      v-for="n in Object.keys(metaliprodukti) "
                      :key="metaliprodukti[n].Slug"
                      v-slot="{ isSelected, toggle }"
                      >

                          <v-card 
                              :color="isSelected ? '#0c0c0c00' : 'white'"
                              @click="toggle"
                              class="ma-2" max-width="300" min-width="300" >

                              
                              <v-img class="white--text align-end" cover height="250px" :src="metaliprodukti[n].SlikaString" lazy-src="https://upload.wikimedia.org/wikipedia/commons/f/fc/No_picture_available.png">
                              
                                  <v-card-title class="transparentna_traka" > {{  $t(metaliprodukti[n].Naziv) }}</v-card-title>
                              </v-img>

                          </v-card>

                      </v-slide-group-item>
                  
        </v-slide-group>


        <v-row justify="center" >
            <v-expand-transition>
                <v-sheet
                
                    v-if="metaliproduktindex != null"
                    :elevation="0"
                    
                    max-width="90%"
                    min-width="90%"


                    
                >
                    <v-container   class=" container" >

                      <v-row >
                        <v-col cols="12" sm="12" >
                          <v-card elevation="0"  min-width="100%">
                                    <v-card-text class="naslov_kartica text-center">
                                      {{ $t(metaliprodukti[metaliproduktindex].Naziv) }}
                                    </v-card-text>
                                </v-card>
                        </v-col>
                      </v-row>
                        <v-row class="justify-between">

                            <v-col  cols="12" sm="6" >
                                <!--span class="podnaslov_white1">{{ $t(metaliprodukti[metaliproduktindex].Naziv) }}</span> <br--> 
                                <!--div class="pozadinakolona_lijevo_kartica">                   
                                  <div class="tekst_kartica" v-html= $t(metaliprodukti[metaliproduktindex].Opis) > </div>   
                                </div-->    

                                <div class="pozadinakolona_lijevo_kartica">                   
                                  <div class="tekst_kartica" > {{skratiOpis($t(metaliprodukti[metaliproduktindex].Opis), 550)}}</div>   
                                </div>                                     
                                                                

                            </v-col>
                            <v-col cols="12" sm="6"  >
                                <div class="pozadinakolona_desno_kartica">
                                    <!--img :src="metaliprodukti[metaliproduktindex].SlikaString"  style="width: 100%;height:350px;" /-->
                                    <v-img cover  :src="metaliprodukti[metaliproduktindex].SlikaString" lazy-src="https://upload.wikimedia.org/wikipedia/commons/f/fc/No_picture_available.png"></v-img>
                                  </div>
                             
                            </v-col>

                        </v-row>
                        <v-row justify="center">
                          <v-col  cols="12" sm="5">
                            <!--v-btn
                                      class="ma-2 dugmad"                                                                                               
                                      variant="outlined"
                                      @click="toggle"
                                    >
                                  
                                      Otvori produkt na stranici proizvođača
                                      <v-icon>
                                          mdi-link
                                      </v-icon>
                                  </v-btn-->    
                          </v-col>
                        </v-row>                  
                    </v-container>
                </v-sheet>
            </v-expand-transition>
        </v-row>
          


      </v-sheet>
    </v-row>
 
    <v-row><v-spacer></v-spacer></v-row>
    
    <v-row><v-spacer></v-spacer></v-row>



    
</template>

<script>
export default {

    data () {
      return {


        metaliproduktindex:null,
        metaliprodukti:[],

        
        //snackbar
        alert:false,
        alerttext:'',
        alertsnackbar:false,
        snackbartekst:'',
        snackbartitle:'',
        multiLine: true,
        ikonasnackbar:'',
        colorsnackbar:'',          
        }

      },
      created() {
        this.loadmetaliprodukti();

    
      },

      methods:{

        skratiOpis(opis, maksimalniBrojKaraktera) {
          
          if (opis.length > maksimalniBrojKaraktera) {
            return opis.substring(0, maksimalniBrojKaraktera) ;
          } else {
            return opis;
          }
        },        


        loadmetaliprodukti(){
          this.metaliprodukti=[
            {Naziv:'produkt-metali-1', Opis:'produkt-metali-opis-1', Slug:"1", SlikaString:"/images/proizvodi/produkt-metali-1.jpg" },
            {Naziv:'produkt-metali-2', Opis:'produkt-metali-opis-2', Slug:"2", SlikaString:"/images/proizvodi/produkt-metali-2.jpg" },
            {Naziv:'produkt-metali-3', Opis:'produkt-metali-opis-3', Slug:"3", SlikaString:"/images/proizvodi/produkt-metali-3.jpg" },
            {Naziv:'produkt-metali-4', Opis:'produkt-metali-opis-4', Slug:"4", SlikaString:"/images/proizvodi/produkt-metali-4.jpg" }


          ]
        },        
        async obavijestHeading(strTekst, tip){

            switch(tip) {
            case 'info':
                this.ikonasnackbar="info";
                this.colorsnackbar="info";
                this.snackbartitle="Info"
                break;
            case 'success':
                this.ikonasnackbar="check_circle";
                this.colorsnackbar="success";
                this.snackbartitle="Obavijest"
                break;
            case 'error':
                this.ikonasnackbar="error";
                this.colorsnackbar="error";
                this.snackbartitle="Greška"
                break; 
            case 'warning':
                this.ikonasnackbar="warning";
                this.colorsnackbar="warning";
                this.snackbartitle="Upozorenje"
                break;                                        
            default:

            }

            this.snackbartekst=strTekst;
            this.alertsnackbar=true;


        },        
      }
    
}
</script>
<style >

</style>