<template>

<row >
  <v-card elevation="0" class="fullpage white1" min-width="100%">
    
    <div  class=" " style="width: 100%; height: 400px">
      <v-img   src="./images/Logo.jpg" lazy-src="https://upload.wikimedia.org/wikipedia/commons/f/fc/No_picture_available.png"></v-img>
    </div>

  </v-card>
</row>


<row id="kontakt" >

  <v-card elevation="0" class="fullpage white1" min-width="100%">
    <card-text>

      <v-card elevation="0" class="pt-4 pb-0 px-8" min-width="100%">
          <v-card-text class="divider line one-line-tamnosiva divider_slova_naslov_tamnosivi text-center">
            {{ $t("o-nama-title") }}
          </v-card-text>
      </v-card>
    </card-text>
  </v-card>

  <!--v-card elevation="0" class="pt-0 pb-4 py-4" min-width="100%">
      <v-card-text class="tekst_white1">
        {{ $t("o-nama-opis") }}
      </v-card-text>
      
    </v-card-->
</row>


<v-row justify="center" class="fullpage white1 pt-4 pb-8" >


<v-container  class=" container" >

  <v-row >
    <v-col cols="12" sm="12" >
      <v-card elevation="0"  min-width="100%">
                <v-card-text class="naslov_kartica text-center">
                  FES System supplier
                </v-card-text>
            </v-card>
    </v-col>
  </v-row>
    <v-row class="justify-between">

        <v-col  cols="12" sm="12" >

            <div class="pozadinakolona_lijevo_kartica_kontakti">                   
              <div class="tekst_kartica" >
                {{ $t("o-nama-opis") }}

                
              </div>   
            </div>                                     
                                            

        </v-col>


    </v-row>
    <v-row justify="center ">
      <v-col  cols="12" sm="5">
        <!--v-btn
                  class="ma-2 dugmad"                                                                                               
                  variant="outlined"
                  @click="toggle"
                >
              
                  
                  <v-icon>
                      mdi-link
                  </v-icon>
              </v-btn-->    
      </v-col>
    </v-row>                  
</v-container>


</v-row>


<row id ="footer" class=" pt-4 " >

<footerbar :inicijalniJezik="en"/>

</row>
</template>

<script>
//import Navigation from '@/components/NavigationMenu.vue';
import Footer from '@/components/FooterForm.vue'
export default {
  components: {
    'footerbar':Footer

  },
};
</script>