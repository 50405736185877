
<!-- ------------------------- HTML SELECT ----------------------------------------------->
<!-- ------------------------- HTML SELECT ----------------------------------------------->
<!-- ------------------------- HTML SELECT ----------------------------------------------->
<!--
<template>
    <select v-model="$i18n.locale">
      <option v-for="(locale, i) in locales" :key="`locale-${i}`" :value="locale">
        {{ locale }}
      </option>
    </select>
</template>
  
<script>
  export default {
    name: "LocaleSwitcher",
    data() {
      return { locales: ["hr", "en", "bs"] };
    }
  };
</script>
-->


<!-- ------------------------- HTML SELECT END ----------------------------------------------->
<!-- ------------------------- HTML SELECT END ----------------------------------------------->
<!-- ------------------------- HTML SELECT END ----------------------------------------------->

<!-- ------------------------- VUETIFY SELECT ----------------------------------------------->
<!-- ------------------------- VUETIFY SELECT ----------------------------------------------->
<!-- ------------------------- VUETIFY SELECT ----------------------------------------------->


<!------------------------------------------------------------------------------------------->
<!-- radi, osnovni sa skracenicama START -->
<!------------------------------------------------------------------------------------------->
<!--
<template>
  <v-select class="select" :items="languages" v-model="language" label=""/>
</template>

  <script>
      export default {
        name: "LocaleSwitcher",
        data: () => ({
          language: "en",
          languages: ["en", "hr", "bs"],

        }),

        watch: {
          language: function (val) {
            this.$i18n.locale = val;
          },
      },

      };
      
  </script>

  
  <style>
  .select{
    width: 180px;
    height: 20px;
  }
  </style>
-->
  <!------------------------------------------------------------------------------------------->
  <!--                       radi, osnovni sa skracenicama KRAJ       -->
  <!------------------------------------------------------------------------------------------->


<!--template>
 
  <v-select class="select" :items="languages" item-value="name" item-title="name"  v-model="language" label="Langauge">
    <template v-slot:selection="{ item }">
      <img :src="item.slika">{{ item.name }}
   </template>
   <template v-slot:item="item">
      {{ item.name }}
   </template>
            
  </v-select>

</template-->

<template>
  <div>
    <!-- ako zelimo dogacajem klika iz selekta zvati metodu onda dodajemo  @update:modelValue="changeSlika" 
    ali ovde nije potrebno, jer se promjena dogadja na watch -->
    <v-select
      class="select"
      v-model="selectedLanguage"
      :items="languages"
      label=""
      item-title="opis"
      item-value="name"
    >

      <template #selection="{item}">
        {{ item.opis }}
        <v-img
          lazy-src="https://upload.wikimedia.org/wikipedia/commons/f/fc/No_picture_available.png"
          :src="selectedSlika"
          width="40"
          height="20"
        ></v-img>
      </template>
    </v-select>

    <!--v-img
      width="100"
      height="100"
      :src="selectedSlika"
      lazy-src="https://upload.wikimedia.org/wikipedia/commons/f/fc/No_picture_available.png"
    ></v-img-->
  </div>
</template>

<script>
export default {
  name: "LocaleSwitcher",

  props:{
            inicijalniJezik:null,
        },

  data() {
    return {
      selectedLanguage: null,
      selectedSlika:null,
      languages: [
        { name: "en", opis: "english"},
        { name: "de", opis: "deutsch"},
        { name: "bs", opis: "bosanski"}
      ]
    };
  },
  created() { 
    this.selectedLanguage= "en";
  },
  computed: {    
         
  },
  mounted() {
    //this.selectedLanguage= "en";
  },
  watch: {

    inicijalniJezik:function(newVal) {
            this.selectedLanguage=newVal;
            //console.log('inicijalni jezik ' + newVal);      

        },

    selectedLanguage: function (val) {
      this.$i18n.locale = val;
      this.changeSlika(val);
      //console.log('selektirano ' + this.selectedLanguage);



    }
  },
  methods:{
    changeSlika:function(value){
      this.selectedSlika="images/flags/" + value +".jpg";
      /* zastave su na https://flagsworld.org/ */
      //console.log ('okino je : ' + value);

    }
  }
};
</script>

  
  <style>
  .select{
    width: 150px;
    height: 50px;
  }
  /*img {
    width: 50px;
    margin-right: 20px;
  }*/
  </style>

  