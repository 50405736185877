<template>

<row id="mapa">
  <v-card elevation="0" class="fullpage white1" min-width="100%">
    
    <div id="map" class=" " style="width: 100%; height: 400px"></div>

  </v-card>
</row>

<row id="kontakt" >

  <v-card elevation="0" class="fullpage white1" min-width="100%">
    <card-text>

      <v-card elevation="0" class="pt-4 pb-0 px-8" min-width="100%">
          <v-card-text class="divider line one-line-tamnosiva divider_slova_naslov_tamnosivi text-center">
            {{ $t("title-kontakt") }}
          </v-card-text>
      </v-card>
    </card-text>

    <v-row justify="center" class=" pt-4 pb-8" >


          <v-container  class=" container" >

            <v-row >
              <v-col cols="12" sm="12" >
                <v-card elevation="0"  min-width="100%">
                          <v-card-text class="naslov_kartica text-center">
                            FES System supplier
                          </v-card-text>
                      </v-card>
              </v-col>
            </v-row>
              <v-row class="justify-between">

                  <v-col  cols="12" sm="12" >

                      <div class="pozadinakolona_lijevo_kartica_kontakti">                   
                        <div class="tekst_kartica" >
                          Teočak, 75000 <br />
                          Ulica i broj<br /><br />
                          ime_emaila@email.com <br />

                          Telefoni: +387 061 123 456, +387 061 456 123, +387 061 321 654

                          
                        </div>   
                      </div>                                     
                                                      

                  </v-col>


              </v-row>
              <v-row justify="center ">
                <v-col  cols="12" sm="5">
                  <!--v-btn
                            class="ma-2 dugmad"                                                                                               
                            variant="outlined"
                            @click="toggle"
                          >
                        
                            
                            <v-icon>
                                mdi-link
                            </v-icon>
                        </v-btn-->    
                </v-col>
              </v-row>                  
          </v-container>


    </v-row>


  </v-card>

</row>



<row id ="footer" class=" pt-4 " >

    <footerbar :inicijalniJezik="en"/>

</row>

</template>

<script>
import Footer from '@/components/FooterForm.vue'
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
export default {

  components: {
    'footerbar':Footer
  },
  mounted() {
    this.initializeMap();
  },
  methods: {
    initializeMap() {

  
      const map = L.map('map').setView([44.5343655594005,18.6843526874947489], 50); // Centar i nivo zumiranja
      
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(map);
      
      // Dodavanje markera
      const marker = L.marker([44.5343655594005,18.6843526874947489]).addTo(map);
      marker.bindPopup('Ovo je tačna lokacija!').openPopup();
    }
  }
};



</script>
<style>



</style>